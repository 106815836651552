import firebase from '@/plugins/firebase';
import store from '@/store';

export default class Account {
    id!: string;
    user!: firebase.User;
    isFullAdmin = false;

    constructor(user?: firebase.User) {
        if (user) {
            this.id = user.uid;
            this.user = user;
        }
    }

    get isLoggedIn(): boolean {
        return !!firebase.auth().currentUser;
    }

    get permissions() {
        return store.getters['role'].permissions;
    }

    get userName() {
        return store.getters['me']?.userName || '';
    }

    get email() {
        return store.getters['me']?.email || this.user?.email || '';
    }

    public async init() {
        return Promise.all([store.dispatch('bindMe', this.user.uid)]);
    }

    public async login(
        email: string,
        password: string
    ): Promise<firebase.auth.UserCredential> {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }

    public async loginWithToken(
        token: string
    ): Promise<firebase.auth.UserCredential> {
        return firebase.auth().signInWithCustomToken(token);
    }
    public async logout(): Promise<void> {
        return firebase.auth().signOut();
    }
}
