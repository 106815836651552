import Vue from 'vue';
import Vuex from 'vuex';
import { firestoreAction, vuexfireMutations } from 'vuexfire';
import modules from './modules';
import firebase from '@/plugins/firebase';

Vue.use(Vuex);

const baseState = {
    me: {} as firebase.firestore.DocumentData,
};

export default new Vuex.Store({
    state: baseState,
    getters: {
        me: (state) => state.me,
    },
    mutations: {
        ...vuexfireMutations,
    },
    actions: {
        bindMe: firestoreAction<typeof baseState, unknown>(
            ({ bindFirestoreRef }, uid) => {
                return bindFirestoreRef(
                    'me',
                    firebase.firestore().doc(`users/${uid}`)
                );
            }
        ),
    },
    modules: modules,
});
