import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import firebase from '@/plugins/firebase';
import { camelCase, upperFirst } from 'lodash';
import rules from '@/rules';
import { firestorePlugin } from 'vuefire';
import Account from './models/account';
import '@/filters';

Vue.use(firestorePlugin);
Vue.config.productionTip = false;
Vue.prototype.$rules = rules;
Vue.prototype.$account = new Account();

const requireComponent = require.context(
    './components',
    true,
    /[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(
        camelCase(
            fileName
                .split('/')
                .pop()
                ?.replace(/\.\w+$/, '')
        )
    );

    Vue.component(componentName, componentConfig.default || componentConfig);
});

router.beforeEach((to, from, next) => {
    Vue.prototype.$isSinglePage = !!to.matched.some((x) => x.meta.isSinglePage);
    const isLoggedIn = !!firebase.auth().currentUser;

    if (!isLoggedIn && to.path !== '/login') {
        next('/login');
    } else if (isLoggedIn && to.path === '/login') {
        next('/');
    } else {
        next();
    }
});

let app: object;
firebase.auth().onAuthStateChanged(async (user) => {
    await (async function () {
        if (user !== null) {
            Vue.prototype.$account = new Account(user);
            await Promise.all([Vue.prototype.$account.init()]);
        } else {
            // force logout
        }
    })();
    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            render: (h) => h(App),
        }).$mount('#app');
    }
});
