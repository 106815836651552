import { Module } from 'vuex';
interface SnackState {
    message: string;
    type: SnackType;
}

export default {
    state: {
        message: '',
        type: undefined,
    },
    mutations: {
        SET_SNACK(state, data) {
            state.message = data.message;
            state.type = data.type;
        },
    },
    actions: {
        setSnack(context, data) {
            context.commit('SET_SNACK', data);
        },
    },
} as Module<SnackState, {}>;
