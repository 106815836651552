const production = {
    extensions: {
        allowedPhoto: ['image/jpeg', 'image/jpg', 'image/gif', 'image/png'],
        allowedResume: [],
    },
    buckets: {
        attachments: 'gs://attachments.staffbyte.ph',
        attachmentsMedia: 'gs://attachments-media.staffbyte.ph',
        photos: 'gs://photos.staffbyte.ph',
        cv: 'gs://cv-attachments.staffbyte.ph',
    },
    MediaURL: {
        attachments: 'https://attachments.staffbyte.ph',
        attachmentsMedia: 'https://attachments-media.staffbyte.ph',
    },
    googleMapKey: 'AIzaSyCPpcYUFIqbzejO0GNDkOpy822yTkKTB_k',
    FCMKeyPair:
        'BAjpx59RY-ZS42UN7P_GABW_IBvtY1mhulKcd5Yd1JqYsnaEMlyRIr-QSkJc-7aDDslwgeGT2ZApdFuCUR-ArhY',
    firebase: {
        apiKey: 'AIzaSyAgxc3aaKCYtC8BaiNY1LyVnLQ5ZpcYKUs',
        authDomain: 'staffbyte.ph',
        databaseURL: 'https://staffbyte-ce9f8.firebaseio.com',
        projectId: 'staffbyte-ce9f8',
        storageBucket: 'staffbyte-ce9f8.appspot.com',
        messagingSenderId: '113166762541',
        appId: '1:113166762541:web:a91030af210d4c21a02ea4',
        measurementId: 'G-6FDYYYN5R4',
    },
    functionsLocation: 'asia-east2',
};

const dev = {
    extensions: {
        allowedPhoto: ['image/jpeg', 'image/jpg', 'image/gif', 'image/png'],
        allowedResume: [],
    },
    buckets: {
        attachments: 'gs://attachments.staging.staffbyte.ph',
        attachmentsMedia: 'gs://attachments-media.staging.staffbyte.ph',
        photos: 'gs://photos.staging.staffbyte.ph',
        cv: 'gs://cv-attachments.staging.staffbyte.ph',
    },
    MediaURL: {
        attachments: 'https://attachments.staging.staffbyte.ph',
        attachmentsMedia: 'https://attachments-media.staging.staffbyte.ph',
    },
    googleMapKey: 'AIzaSyCPpcYUFIqbzejO0GNDkOpy822yTkKTB_k',
    FCMKeyPair:
        'BPJcpGwjitO1xjEvhAyX3wMcho4J5oZD1bSgA1h7U4U6ldY5ELOf6B-qmmCngbHn5JdfxVYl29YvVIpLHa25PF4',
    firebase: {
        apiKey: 'AIzaSyCPpcYUFIqbzejO0GNDkOpy822yTkKTB_k',
        authDomain: 'wfhome-38cab.firebaseapp.com',
        databaseURL: 'https://wfhome-38cab.firebaseio.com',
        projectId: 'wfhome-38cab',
        storageBucket: 'wfhome-38cab.appspot.com',
        messagingSenderId: '869580916641',
        appId: '1:869580916641:web:937dab3fe0d1e73e636de0',
        measurementId: 'G-QH7JKBWBBE',
    },
    functionsLocation: 'asia-east2',
};

const config = process.env.NODE_ENV === 'production' ? production : dev;
export default config;
