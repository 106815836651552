import Vue from 'vue';
import moment from 'moment';
import { startCase } from 'lodash';
import camelCase from 'lodash/camelCase';

Vue.filter('years', (value: number) => {
    if (value > 1) {
        return `${value} years`;
    }

    return `${value} year`;
});

Vue.filter('numberFormat', (value: number) => {
    return value.toLocaleString();
});

Vue.filter('moneyFormat', (value: number) => {
    return new Intl.NumberFormat('tl-PH', {
        style: 'currency',
        currency: 'PHP',
    }).format(value);
});

Vue.filter('capitalize', (value: string) => {
    if (!value) {
        return '';
    }

    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('dateFromNow', (value: firebase.default.firestore.Timestamp) => {
    if (!value) {
        return '';
    }
    return moment(value.toDate()).fromNow();
});

Vue.filter('date', (value: firebase.default.firestore.Timestamp) => {
    if (!value) {
        return '';
    }
    return moment(value.toDate()).format('MMMM DD, YYYY');
});

Vue.filter('time', (value: firebase.default.firestore.Timestamp) => {
    if (!value) {
        return '';
    }
    return moment(value.toDate()).format('h:mm a');
});

Vue.filter('startCase', (value: string) => {
    if (!value) {
        return '';
    }

    return startCase(value);
});

Vue.filter('camelCalse', (value: string) => {
    if (!value) {
        return '';
    }

    return camelCase(value);
});
