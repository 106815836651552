



























































import { Component, Vue } from 'vue-property-decorator';
import BaseClass from './base';

@Component
export default class App extends BaseClass {
    menuItems = [
        {
            title: 'Dashboard',
            icon: 'home',
            to: '/',
            show: true,
        },
        {
            title: 'Approve Job Ads',
            icon: 'done',
            to: '/approve-ads',
            show: true,
        },
        {
            title: 'Admin Access',
            icon: 'manage_accounts',
            to: '/administrators',
            show: true,
        },
        {
            title: 'Import Job Seekers (Jobstreet)',
            icon: 'save_alt',
            to: '/jobseeker-import',
            show: true,
        },
        {
            title: 'Import Job Seekers (CSV)',
            icon: 'save',
            to: '/jobseeker-import-csv',
            show: true,
        },
    ];

    async logout() {
        await this.firebase.auth().signOut();
        document.location.href = '/';
    }
}
