export default {
    email: (v: string) => /.*@.*/.test(v) || 'Invalid email.',
    notEmpty: (v: string) => !!v || 'This field must not be empty.',
    password: (v: string) =>
        v.length > 8 || 'Password must be more than 8 characters.',
    phoneNumber: (v: string) =>
        (v.length >= 9 && v.length <= 15) || 'Invalid phone format.',
    moreThanZero: (v: string) =>
        Number.parseInt(v) > 0 || 'Value must be more than 0.',
};
