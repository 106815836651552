import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            isSinglePage: true,
        },
    },
    {
        path: '/jobseeker-import',
        name: 'ImportJobSeeker',
        component: () => import('@/views/ScrapeJobseeker.vue'),
    },
    {
        path: '/jobseeker-import-csv',
        name: 'ImportJobSeekerCSV',
        component: () => import('@/views/ImportCsv.vue'),
    },
    {
        path: '/approve-ads',
        name: 'ApproveJobAds',
        component: () => import('@/views/ApproveAds.vue'),
    },
    {
        path: '/administrators',
        name: 'Administrators',
        component: () => import('@/views/Administrators.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
