import { Component, Vue } from 'vue-property-decorator';
import firebase from '@/plugins/firebase';

@Component
export default class BaseClass extends Vue {
    loading = false;
    firebase = firebase;

    snackMessage(message: string, type?: SnackType) {
        this.$store.dispatch('snackbar/setSnack', {
            message: message,
            type: type,
        });
    }
}
